import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Link } from "@mui/material";
import { PATH_AUTH } from "../../routes/paths";
import { getOnlySiteSettingAsync, getTestInstructionAsync } from "../../redux/async.api";

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getOnlySiteSettingLoader, getOnlySiteSettingData = [] } = useSelector((state) => state.getOnlySiteSetting)
  const { siteLogo, siteAuthorName } = getOnlySiteSettingData

  
  useEffect(() => {
    dispatch(getOnlySiteSettingAsync())
  }, [])

  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 100,
        height: 60,
        display: "inline-flex",
        ...sx,
      }}
      {...other}
    >
      <img src={siteLogo} alt={siteAuthorName} />
      {/* <svg width="60" height="60" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.275879" width="38" height="38" rx="19" fill={PRIMARY_MAIN}/>
        <path d="M15.629 8.70268C13.5451 9.28494 11.1241 10.8478 9.86769 12.472C8.91769 13.6672 8.91769 13.6672 9.56124 13.9736C10.1129 14.2188 10.3887 14.0656 11.2774 13.0849C11.8596 12.4414 13.2387 11.4914 14.3419 10.9398C16.1193 10.0511 16.7016 9.92849 18.6935 9.92849C21.4822 9.92849 23.4435 10.7253 25.3129 12.564C26.2935 13.5446 26.7532 13.7591 27.2435 13.6059C28.1629 13.3301 28.0709 12.993 26.6 11.5527C23.8112 8.85591 19.337 7.69139 15.629 8.70268Z" fill="white" />
        <path d="M15.9354 12.0738C15.1692 12.3189 13.9434 13.0238 13.208 13.6367C11.9822 14.7092 11.9209 14.8012 12.4418 15.1996C12.9322 15.5367 13.116 15.4754 13.9434 14.6786C16.3338 12.3802 19.9805 12.2576 22.7692 14.4334C23.6886 15.1689 24.087 15.2915 24.5773 15.077C25.1596 14.8318 25.1289 14.7399 23.9644 13.6979C21.8805 11.8286 18.7854 11.185 15.9354 12.0738Z" fill="white" />
        <path d="M16.8548 14.8937C16.6096 14.9856 15.9967 15.3533 15.5064 15.6904L14.6483 16.3033L15.3225 16.6711C15.8741 16.9469 16.2112 16.9162 17.1919 16.4259C18.3564 15.8743 18.479 15.8437 19.7354 16.334C21.8806 17.1614 22.8919 16.3953 21.0838 15.292C20.1032 14.7098 17.9273 14.4953 16.8548 14.8937Z" fill="white" />
        <path d="M11.9515 20.7163V25.2824L14.158 26.355C15.3532 26.9372 16.8548 27.7647 17.4983 28.2243C18.1725 28.7147 18.7548 28.9292 18.8467 28.776C18.9386 28.5921 20.0419 27.9179 21.237 27.2437C22.4628 26.5695 23.3209 26.0179 23.137 26.0179C22.9838 26.0179 21.8806 26.5389 20.7161 27.1824L18.6015 28.3163L15.4451 26.6921L12.258 25.0679V21.1147V17.1614L14.6483 18.2647C15.9354 18.8776 17.3757 19.6131 17.8048 19.9195C18.479 20.3792 18.6628 20.4098 19.0306 20.0421C19.3983 19.6743 24.3015 17.1308 24.6693 17.1308C24.7306 17.1308 24.8532 19.0308 24.9144 21.3292L25.0064 25.5582L25.0677 20.8695C25.0983 18.326 25.0677 16.2114 25.0064 16.2114C24.5773 16.2114 19.6128 19.0921 19.1532 19.5824C18.6015 20.1953 18.5709 20.1953 17.958 19.6437C17.5902 19.3066 16.1193 18.3872 14.6483 17.6211L11.9515 16.1502V20.7163Z" fill="white" />
        <path d="M10.1129 22.1255V26.8448L11.5839 27.3964C12.3807 27.7029 14.2807 28.469 15.8129 29.1432L18.6016 30.3384L20.1032 29.6029C20.9307 29.1739 22.8 28.4077 24.3016 27.8561L26.9678 26.8448V22.1255C26.9678 18.4787 26.8758 17.4061 26.6 17.4981C26.2936 17.59 26.171 18.8464 26.1097 21.9416L26.0178 26.2319L22.4323 27.8255C20.471 28.6835 18.6629 29.3884 18.4484 29.3884C18.2339 29.3884 16.4258 28.6835 14.4032 27.7948L10.7258 26.2013V22.0029C10.7258 19.5206 10.6032 17.7126 10.4194 17.59C10.2049 17.4674 10.1129 19.061 10.1129 22.1255Z" fill="white" />
        <path d="M13.4839 19.5207C13.4839 19.6739 14.0661 20.0416 14.771 20.3481C15.4758 20.6239 16.4258 21.1142 16.9161 21.4513C17.3758 21.7884 17.7742 21.9416 17.7742 21.7578C17.7742 21.5739 16.9161 20.9916 15.8742 20.44C13.3613 19.1223 13.4839 19.1836 13.4839 19.5207Z" fill="white" />
        <path d="M21.4209 20.195C20.5016 20.6547 19.5516 21.2983 19.3064 21.5741C18.908 22.0338 22.7387 20.1644 23.4435 19.5209C23.9951 19.0305 23.1371 19.3063 21.4209 20.195Z" fill="white" />
        <path d="M21.758 22.0641C20.8387 22.5545 19.7968 23.2593 19.4597 23.5964C19.0919 23.9641 19.6129 23.7496 20.6548 23.1367C21.6661 22.5238 22.7387 21.9722 23.0451 21.8496C23.3516 21.7577 23.5968 21.5432 23.5968 21.3899C23.5968 21.0222 23.6887 20.9916 21.758 22.0641Z" fill="white" />
        <path d="M13.4838 21.5742C13.4838 21.6661 14.3418 22.1564 15.4144 22.708C17.866 23.9951 17.8967 24.0258 17.6515 23.5968C17.5289 23.4129 16.6096 22.8306 15.6289 22.3403C13.7902 21.4209 13.4838 21.2984 13.4838 21.5742Z" fill="white" />
        <path d="M13.4838 23.4736C13.4838 23.6268 14.4644 24.2397 15.6902 24.8833C16.8854 25.5268 17.6822 25.8026 17.4676 25.5268C16.9773 24.9445 13.4838 23.1365 13.4838 23.4736Z" fill="white" />
        <path d="M21.4516 24.2094C20.5322 24.7304 19.5516 25.3433 19.3064 25.5885C18.8774 26.0481 22.8306 24.0562 23.4435 23.5046C23.9951 23.0143 23.137 23.3207 21.4516 24.2094Z" fill="white" />
        <defs>
          <linearGradient id="paint0_linear_1366_1267" x1="19" y1="0.275879" x2="19" y2="38.2759" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF8331" />
            <stop offset="1" stopColor="#DC5F19" />
          </linearGradient>
        </defs>
      </svg> */}

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to={PATH_AUTH.base} component={RouterLink} sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
});

export default Logo;
