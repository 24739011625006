import { Navigate, useRoutes } from "react-router-dom";
import MainLayout from "../layouts/main";
import SimpleLayout from "../layouts/simple";
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
import {
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBankingPage,
  GeneralBookingPage,
  Dashboard,
  GeneralAnalyticsPage,
  // Dashboard: User
  Masters,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  // Dashboard: Ecommerce
  EcommerceShopPage,
  EcommerceCheckoutPage,
  EcommerceProductListPage,
  EcommerceProductEditPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  // Dashboard: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,
  // Dashboard: FileManager
  FileManagerPage,
  // Dashboard: App
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  HomePage,
  FaqsPage,
  Faq,
  AboutPage,
  Contact,
  PricingPage,
  PaymentPage,
  ComingSoonPage,
  MaintenancePage,
  //
  ComponentsOverviewPage,
  FoundationColorsPage,
  FoundationTypographyPage,
  FoundationShadowsPage,
  FoundationGridPage,
  FoundationIconsPage,
  //
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  // MUITreesViewPage,
  //
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  Course,
  Board,
  Batch,
  AddBatches,
  AddCourse,
  AddBoards,
  Students,
  Class,
  StudentProfile,
  AddClass,
  Subject,
  AddSubject,
  BatchDate,
  AddBatchDate,
  Teachers,
  Chapter,
  AddChapter,
  Banner,
  AddBanner,
  AddTeacher,
  Syllabus,
  CreateAccount,
  ShortsPage,
  MyBookmarksPage,
  MentorshipPage,
  ScholarshipTestPage,
  HelpSupport,
  UpdateProfilePage,
  Subscription,
  ReferAndEarn,
  Faqs,
  FavouriteShortsPage,
  ShortsVideoPlayer,
  VideoPlayer,
  SyllabusVideoPlayer,
  AssignmentPage,
  InstructionPage,
  RevisionDetail,
  SolutionsByStudents,
  SolutionsByTeacher,
  Doubts,
  CreateTestPage,
  ViewExplanationPage,
  AnswerKeyPage,
  ReportSummaryPage,
  SyllabusPlayerId,
  SelectTestChapters,
  EventVideoPage,
  EventPage,
  MyEventPage,
  Revision,
  StartTest,
  TeacherCalenderPage,
  MyProfile,
  OrderDetail,
  DemoCalenderPage,
  MyLearningReports,
  MentorshipBookCalenderPage,
  CountSession,
  RecentActivityPage,
  ViewAllNotificationsPage,
  ViewAllNotifications,
  Contents,
  Courses,
  ModalPaper,
  CourseList,
  DisplayContent,
  MyFreeEventPage,
  LiveCourses,
  PhonepayPaymentResponse,
  FreeNotes,
  FreePyqs,
  DisplayModalPaper,
  FreeNotesChapter,
  DisplayNotesData,
  DisplayPyqs,
  FreePyqsVideo,
  ImpQuestion,
  DisplayImpChapter,
  DisplayImpQuestion,
} from "./elements";
import Quiz from "../pages/dashboard/Quiz/Quiz";
import { ProtectedRoute } from "../auth/ProtectedRoute";
import { useSelector } from "react-redux";
import { ResourceOpen } from "pages/Syllabus/Syllabus/ResourceOpen";
import DisplayNotes from "pages/freeresources/Notes/DisplayNotes";

export default function Router() {
  const { userInfo: { accessToken = "", loginStatus = false } = "" } =
    useSelector((state) => state?.userInfo);

  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: <LoginPage />,
        },
        {
          path: "create-account",
          element: <CreateAccount />,
        },
        {
          element: <CompactLayout />,
          children: [
            { path: "reset-password", element: <ResetPasswordPage /> },
            { path: "new-password", element: <NewPasswordPage /> },
            { path: "verify", element: <VerifyCodePage /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <Navigate replace to="/app" />,
      index: true,
    },
    {
      path: "register",
      element: <RegisterPage />,
    },
    // seperate router for create account.
    {
      path: "create-account",
      element: (
        <ProtectedRoute authToken={accessToken} authStatus={loginStatus}>
          <CreateAccount />
        </ProtectedRoute>
      ),
    },

    {
      path: "start-test/:id",
      element: (
        <ProtectedRoute authToken={accessToken} authStatus={loginStatus}>
          <StartTest />
        </ProtectedRoute>
      ),
    },

    {
      path: "app",
      element: (
        <ProtectedRoute authToken={accessToken} authStatus={loginStatus}>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          element: <Navigate to="/app/dashboard" replace />,
          index: true,
        },
        { path: "dashboard", element: <Dashboard /> },
        { path: "quiz", element: <Quiz /> },
        { path: "player", element: <SyllabusVideoPlayer /> },
        { path: "syllabus", element: <Syllabus /> },
        { path: "chapters", element: <Contents /> },
        { path: "chapters/:id", element: <DisplayContent /> },
        { path: "courses", element: <Courses /> },
        { path: "modalpaper", element: <ModalPaper /> },
        { path: "modalpaper/:id", element: <DisplayModalPaper /> },
        { path: "notes", element: <FreeNotes /> },
        { path: "notes-chapter", element: <FreeNotesChapter /> },
        { path: "notes-chapter/:id", element: <DisplayNotesData /> },
        { path: "pyqs", element: <FreePyqs /> },
        { path: "pyqs/:id", element: <DisplayPyqs /> },
        { path: "pyqs/play/:id", element: <FreePyqsVideo /> },
        { path: "imp-question", element: <ImpQuestion /> },
        { path: "imp-question/chapters", element: <DisplayImpChapter /> },
        { path: "imp-question/chapters/:id", element: <DisplayImpQuestion /> },
        { path: "live-courses/:id", element: <LiveCourses /> },
        { path: "courses/:id", element: <CourseList /> },
        { path: "help-Support", element: <HelpSupport /> },
        { path: "calender", element: <TeacherCalenderPage /> },
        { path: "schedule-demo", element: <DemoCalenderPage /> },
        { path: "mentorship-book", element: <MentorshipBookCalenderPage /> },
        { path: "UpdateProfile", element: <UpdateProfilePage /> },
        { path: "myLearningReports", element: <MyLearningReports /> },
        { path: "recentActivity", element: <RecentActivityPage /> },
        { path: "referearn", element: <ReferAndEarn /> },
        { path: "subscription", element: <Subscription /> },
        { path: "faqs", element: <Faqs /> },
        { path: "revision", element: <Revision /> },
        { path: "doubts", element: <Doubts /> },
        { path: "revision/revisionDetail", element: <RevisionDetail /> },
        { path: "solutionsByStudents", element: <SolutionsByStudents /> },
        { path: "solutionsByTeacher", element: <SolutionsByTeacher /> },
        { path: "master", element: <Masters /> },
        { path: "master/courses", element: <Course /> },
        { path: "master/class", element: <Class /> },
        { path: "master/class/addclass", element: <AddClass /> },
        { path: "master/class/updateclass/:id", element: <AddClass /> },
        { path: "master/courses/add", element: <AddCourse /> },
        { path: "master/courses/:id", element: <AddCourse /> },
        { path: "master/board/add", element: <AddBoards /> },
        { path: "master/board", element: <Board /> },
        { path: "master/board/add", element: <AddBoards /> },
        { path: "master/board/update/:id", element: <AddBoards /> },
        { path: "master/batch", element: <Batch /> },
        { path: "master/batch/add", element: <AddBatches /> },
        { path: "master/batch/update/:id", element: <AddBatches /> },
        { path: "master/subject", element: <Subject /> },
        { path: "master/subject/add", element: <AddSubject /> },
        { path: "master/subject/update/:id", element: <AddSubject /> },
        { path: "master/batchDate", element: <BatchDate /> },
        { path: "master/batchDate/update/:id", element: <AddBatchDate /> },
        { path: "master/batchDate/add", element: <AddBatchDate /> },
        { path: "master/chapter", element: <Chapter /> },
        { path: "master/chapter/add", element: <AddChapter /> },
        { path: "master/chapter/update/:id", element: <AddChapter /> },
        { path: "master/banner", element: <Banner /> },
        { path: "master/banner/add", element: <AddBanner /> },
        { path: "master/banner/update/:id", element: <AddBanner /> },
        { path: "students", element: <Students /> },
        { path: "student/:id", element: <StudentProfile /> },
        { path: "teachers", element: <Teachers /> },
        { path: "teacher/add", element: <AddTeacher /> },
        { path: "teacher/update/:id", element: <AddTeacher /> },
        { path: "banking", element: <GeneralBankingPage /> },
        { path: "booking", element: <GeneralBookingPage /> },
        { path: "file", element: <GeneralFilePage /> },
        { path: "all-notifications", element: <ViewAllNotifications /> },
        { path: "syllabus/:id", element: <SyllabusPlayerId /> },
        { path: "syllabus/resource/:id", element: <ResourceOpen /> },
        { path: "shorts/:link", element: <VideoPlayer /> },
        { path: "instruction/:id", element: <InstructionPage /> },
        { path: "assignment", element: <AssignmentPage /> },
        // shorts..
        { path: "shorts", element: <ShortsPage /> },
        { path: "favourite-Shorts", element: <FavouriteShortsPage /> },
        //myBookmarks
        { path: "myBookmarks", element: <MyBookmarksPage /> },
        //Phonepe Payment
        {
          path: "payment/confirmation",
          element: <PhonepayPaymentResponse />,
        },
        //mentorship
        {
          path: "mentorship",
          element: (
            <CountSession>
              <MentorshipPage />
            </CountSession>
          ),
        },
        //ScholarshipTest
        { path: "scholarshipTest", element: <ScholarshipTestPage /> },
        { path: "shorts/:link", element: <VideoPlayer /> },
        { path: "myProfile", element: <MyProfile /> },
        { path: "orderDetail", element: <OrderDetail /> },
        {
          path: "e-commerce",
          children: [
            {
              element: <Navigate to="/dashboard/e-commerce/shop" replace />,
              index: true,
            },
            { path: "shop", element: <EcommerceShopPage /> },
            { path: "product/:name", element: <EcommerceProductDetailsPage /> },
            { path: "list", element: <EcommerceProductListPage /> },
            { path: "product/new", element: <EcommerceProductCreatePage /> },
            {
              path: "product/:name/edit",
              element: <EcommerceProductEditPage />,
            },
            { path: "checkout", element: <EcommerceCheckoutPage /> },
          ],
        },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: "profile", element: <UserProfilePage /> },
            { path: "cards", element: <UserCardsPage /> },
            { path: "list", element: <Masters /> },
            { path: "new", element: <UserCreatePage /> },
            { path: ":name/edit", element: <UserEditPage /> },
            { path: "account", element: <UserAccountPage /> },
          ],
        },
        {
          path: "invoice",
          children: [
            {
              element: <Navigate to="/dashboard/invoice/list" replace />,
              index: true,
            },
            { path: "list", element: <InvoiceListPage /> },
            { path: ":id", element: <InvoiceDetailsPage /> },
            { path: ":id/edit", element: <InvoiceEditPage /> },
            { path: "new", element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: "blog",
          children: [
            {
              element: <Navigate to="/dashboard/blog/posts" replace />,
              index: true,
            },
            { path: "posts", element: <BlogPostsPage /> },
            { path: "post/:title", element: <BlogPostPage /> },
            { path: "new", element: <BlogNewPostPage /> },
          ],
        },
        { path: "files-manager", element: <FileManagerPage /> },
        { path: "test/create-test", element: <CreateTestPage /> },
        { path: "view-explanation", element: <ViewExplanationPage /> },
        { path: "answer-key", element: <AnswerKeyPage /> },
        { path: "report", element: <ReportSummaryPage /> },
        { path: "select-test-chapter", element: <SelectTestChapters /> },
        { path: "event", element: <EventPage /> },
        { path: "event/:vidId", element: <EventVideoPage /> },
        { path: "my-events", element: <MyEventPage /> },
        { path: "free-event", element: <MyFreeEventPage /> },
        {
          path: "mail",
          children: [
            {
              element: <Navigate to="/dashboard/mail/all" replace />,
              index: true,
            },
            { path: "label/:customLabel", element: <MailPage /> },
            { path: "label/:customLabel/:mailId", element: <MailPage /> },
            { path: ":systemLabel", element: <MailPage /> },
            { path: ":systemLabel/:mailId", element: <MailPage /> },
          ],
        },
        {
          path: "chat",
          children: [
            { element: <ChatPage />, index: true },
            { path: "new", element: <ChatPage /> },
            { path: ":conversationKey", element: <ChatPage /> },
          ],
        },
        { path: "calendar", element: <CalendarPage /> },
        { path: "kanban", element: <KanbanPage /> },
        { path: "permission-denied", element: <PermissionDeniedPage /> },
        { path: "blank", element: <BlankPage /> },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        // { element: <RegisterPage />, index: true },
        { path: "about-us", element: <AboutPage /> },
        { path: "contact-us", element: <Contact /> },
        { path: "faqs", element: <FaqsPage /> },
        // Demo Components
        {
          path: "components",
          children: [
            { element: <ComponentsOverviewPage />, index: true },
            {
              path: "foundation",
              children: [
                {
                  element: (
                    <Navigate to="/components/foundation/colors" replace />
                  ),
                  index: true,
                },
                { path: "colors", element: <FoundationColorsPage /> },
                { path: "typography", element: <FoundationTypographyPage /> },
                { path: "shadows", element: <FoundationShadowsPage /> },
                { path: "grid", element: <FoundationGridPage /> },
                { path: "icons", element: <FoundationIconsPage /> },
              ],
            },
            {
              path: "mui",
              children: [
                {
                  element: <Navigate to="/components/mui/accordion" replace />,
                  index: true,
                },
                { path: "accordion", element: <MUIAccordionPage /> },
                { path: "alert", element: <MUIAlertPage /> },
                { path: "autocomplete", element: <MUIAutocompletePage /> },
                { path: "avatar", element: <MUIAvatarPage /> },
                { path: "badge", element: <MUIBadgePage /> },
                { path: "breadcrumbs", element: <MUIBreadcrumbsPage /> },
                { path: "buttons", element: <MUIButtonsPage /> },
                { path: "checkbox", element: <MUICheckboxPage /> },
                { path: "chip", element: <MUIChipPage /> },
                { path: "data-grid", element: <MUIDataGridPage /> },
                { path: "dialog", element: <MUIDialogPage /> },
                { path: "list", element: <MUIListPage /> },
                { path: "menu", element: <MUIMenuPage /> },
                { path: "pagination", element: <MUIPaginationPage /> },
                { path: "pickers", element: <MUIPickersPage /> },
                { path: "popover", element: <MUIPopoverPage /> },
                { path: "progress", element: <MUIProgressPage /> },
                { path: "radio-button", element: <MUIRadioButtonsPage /> },
                { path: "rating", element: <MUIRatingPage /> },
                { path: "slider", element: <MUISliderPage /> },
                { path: "stepper", element: <MUIStepperPage /> },
                { path: "switch", element: <MUISwitchPage /> },
                { path: "table", element: <MUITablePage /> },
                { path: "tabs", element: <MUITabsPage /> },
                { path: "textfield", element: <MUITextFieldPage /> },
                { path: "timeline", element: <MUITimelinePage /> },
                { path: "tooltip", element: <MUITooltipPage /> },
                { path: "transfer-list", element: <MUITransferListPage /> },
                // { path: "tree-view", element: <MUITreesViewPage /> },
              ],
            },
            {
              path: "extra",
              children: [
                {
                  element: <Navigate to="/components/extra/animate" replace />,
                  index: true,
                },
                { path: "animate", element: <DemoAnimatePage /> },
                { path: "carousel", element: <DemoCarouselsPage /> },
                { path: "chart", element: <DemoChartsPage /> },
                {
                  path: "copy-to-clipboard",
                  element: <DemoCopyToClipboardPage />,
                },
                { path: "editor", element: <DemoEditorPage /> },
                {
                  path: "form-validation",
                  element: <DemoFormValidationPage />,
                },
                { path: "image", element: <DemoImagePage /> },
                { path: "label", element: <DemoLabelPage /> },
                { path: "lightbox", element: <DemoLightboxPage /> },
                { path: "map", element: <DemoMapPage /> },
                { path: "mega-menu", element: <DemoMegaMenuPage /> },
                { path: "multi-language", element: <DemoMultiLanguagePage /> },
                { path: "navigation-bar", element: <DemoNavigationBarPage /> },
                {
                  path: "organization-chart",
                  element: <DemoOrganizationalChartPage />,
                },
                { path: "scroll", element: <DemoScrollbarPage /> },
                { path: "snackbar", element: <DemoSnackbarPage /> },
                { path: "text-max-line", element: <DemoTextMaxLinePage /> },
                { path: "upload", element: <DemoUploadPage /> },
              ],
            },
          ],
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: "pricing", element: <PricingPage /> },
        { path: "payment", element: <PaymentPage /> },
        // { path: "start-test", element: <StartTest /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoonPage /> },
        { path: "maintenance", element: <MaintenancePage /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
